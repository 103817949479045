function makeAppConfig() {
    let overrideWithUatUrl = false;
    let localhostUrl = overrideWithUatUrl?'https://hhtportal-test.hotpoint.co.uk':'http://localhost:8080';

    let hostname = window.location.hostname;
    let isLocal = hostname.startsWith('localhost')||hostname.startsWith('127.0.0.1');
    let hostUrl = isLocal?localhostUrl:(window.location.protocol+"//"+hostname);

    //console.log("isLocal:"+isLocal+", localhostUrl:"+localhostUrl+", hostname:"+hostname+", hostUrl:"+hostUrl);
    let AppConfig = {
        frontEndUrl: isLocal?'http://localhost:4200':hostUrl,
        env:(isLocal ? 'local' : ((hostUrl.indexOf('test')!=-1)?'uat':'prod')),
        isProdEnvironment: !(isLocal || hostUrl.indexOf('test')!=-1),
        brand: 'Whirlpool',
        appUrl: hostUrl+"/spring-web/v4",
        google_client_id: '105511446126-csjc40bt6cbkcmmqrb8els7p0anh6uat.apps.googleusercontent.com',
        microsoft_client_id: '1bc9bae4-c68d-4248-8ec1-aaea9271e6d8',
        epwToken:'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I',
        favicon:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/favicon_beko.png',
        main_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        white_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoWhiteWithNoBg03.png',
        blue_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        cacheVersion:43
    };
    return AppConfig; 
} 
export const APPCONFIG = makeAppConfig();